import {
  Grid, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  TextField,
  IconButton
} from "@mui/material";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import { modelList } from "../utils/Config";

type Model = typeof modelList[number];

interface SettingsDialogProps {
  settingsDialogOpen: boolean;
  setSettingsDialogOpen: (settingsDialogOpen: boolean) => void;
  selectedModel: Model;
  setSelectedModel: (model: Model) => void;
  maxOutputTokens: number;
  setMaxOutputTokens: (maxOutputTokens: number) => void;
  historyCount: number;
  setHistoryCount: (historyCount: number) => void;
}

function SettingsDialog({
  settingsDialogOpen,
  setSettingsDialogOpen,
  selectedModel,
  setSelectedModel,
  maxOutputTokens,
  setMaxOutputTokens,
  historyCount,
  setHistoryCount
}: SettingsDialogProps): JSX.Element {

  return (
    <Dialog
      open={settingsDialogOpen}
      onClose={() => setSettingsDialogOpen(false)}
    >
      <DialogTitle>
        {"設定"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>LLMモデル</FormLabel>
              <RadioGroup
                value={selectedModel}
                onChange={e => setSelectedModel(e.target.value)}
              >
                {modelList.map(model => (
                  <FormControlLabel
                    key={model}
                    value={model}
                    control={<Radio />}
                    label={model}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}
            container spacing={2}
          >
            <Grid item xs={12} xl={6}>
              <TextField
                type="number"
                value={maxOutputTokens}
                onChange={e => setMaxOutputTokens(Number(e.target.value))}
                error={maxOutputTokens < 1}
                helperText={"1以上"}
                label="最大出力トークン"
                InputProps={{
                  inputProps: {
                    min: 1
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <TextField
                type="number"
                value={historyCount}
                onChange={e => setHistoryCount(Number(e.target.value))}
                error={historyCount < 0}
                helperText={"0以上"}
                label="送信する履歴数"
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Tooltip arrow
          title="閉じる"
        >
          <IconButton
            onClick={() => setSettingsDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsDialog;
