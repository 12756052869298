import { useState, useEffect, useRef } from 'react';
import {
  Container, Box
} from "@mui/material";
import { modelList } from '../utils/Config';
import Message from "../components/Message";
import SettingsDialog from '../components/SettingsDialog';
import HistoryList from '../components/HistoryList';
import Input from '../components/Input';

type Role = "user" | "model";
type History = {
  role: Role;
  parts: { text: string }[];
};
type Model = typeof modelList[number];
type Severity = "success" | "info" | "warning" | "error";

function Main(): JSX.Element {
  const [historyList, setHistoryList] = useState<History[]>([]);

  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<Model>("gemini-1.5-flash");
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>(8000);
  const [historyCount, setHistoryCount] = useState<number>(2);

  const [messageText, setMessageText] = useState<string>("");
  const [messageSeverity, setMessageSeverity] = useState<Severity>("info");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);

  // 画面の一番下までスクロールする
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [historyList]);

  return (
    <Container maxWidth="xl">
      <HistoryList
        historyList={historyList}
      />

      <div ref={scrollBottomRef} />
      <Box sx={{ mb: 16 }} />

      <Input
        historyList={historyList}
        setHistoryList={setHistoryList}
        selectedModel={selectedModel}
        maxOutputTokens={maxOutputTokens}
        historyCount={historyCount}
        setSettingsDialogOpen={setSettingsDialogOpen}
        setMessageText={setMessageText}
        setMessageSeverity={setMessageSeverity}
        setMessageOpen={setMessageOpen}
      />

      <Message
        messageText={messageText}
        messageSeverity={messageSeverity}
        messageOpen={messageOpen}
        setMessageOpen={setMessageOpen}
      />

      <SettingsDialog
        settingsDialogOpen={settingsDialogOpen}
        setSettingsDialogOpen={setSettingsDialogOpen}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        maxOutputTokens={maxOutputTokens}
        setMaxOutputTokens={setMaxOutputTokens}
        historyCount={historyCount}
        setHistoryCount={setHistoryCount}
      />
    </Container>
  );
}

export default Main;
