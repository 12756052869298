import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import {
  Grid,
  Avatar, Paper
} from "@mui/material";
import {
  SmartToy as SmartToyIcon
} from "@mui/icons-material";

type Role = "user" | "model";
type History = {
  role: Role;
  parts: { text: string }[];
};
interface HistoryListProps {
  historyList: History[];
};

function HistoryList({
  historyList
}: HistoryListProps): JSX.Element {
  return (
    <Grid container spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "start",
        mt: 1
      }}
    >
      {historyList.map((msg, index) => (
        <Grid item xs={12}
          key={index}
        >
          <Paper
            variant={msg.role === 'user' ? 'outlined' : 'elevation'}
            elevation={msg.role === 'user' ? 0 : 3}
            square={msg.role === 'user'}
            sx={{ p: 1 }}
          >
            {msg.role !== 'user' &&
              <Avatar
                variant="rounded"
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: 'primary.main',
                }}
              >
                <SmartToyIcon fontSize='small' />
              </Avatar>
            }
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeExternalLinks]}
              components={{
                code({ node, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return match ? (
                    <SyntaxHighlighter
                      // @ts-ignore
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                }
              }}
            >
              {msg.parts[0].text}
            </ReactMarkdown>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default HistoryList;
