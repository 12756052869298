import { useState } from 'react';
import {
  Container, Box, Tooltip,
  Paper,
  TextField, IconButton, CircularProgress
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Send as SendIcon
} from "@mui/icons-material";
import { modelList } from '../utils/Config';

type Role = "user" | "model";
type History = {
  role: Role;
  parts: { text: string }[];
};
type Model = typeof modelList[number];
type Severity = "success" | "info" | "warning" | "error";
interface InputProps {
  historyList: History[];
  setHistoryList: React.Dispatch<React.SetStateAction<History[]>>;
  selectedModel: Model;
  maxOutputTokens: number;
  historyCount: number;
  setSettingsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageText: React.Dispatch<React.SetStateAction<string>>;
  setMessageSeverity: React.Dispatch<React.SetStateAction<Severity>>;
  setMessageOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Input({
  historyList,
  setHistoryList,
  selectedModel,
  maxOutputTokens,
  historyCount,
  setSettingsDialogOpen,
  setMessageText,
  setMessageSeverity,
  setMessageOpen
}: InputProps): JSX.Element {
  const [input, setInput] = useState<string>("");
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const createMessage = (text: string, severity: Severity) => {
    setMessageText(text);
    setMessageOpen(true);
    setMessageSeverity(severity);
  }

  const send = async () => {
    if (!input.trim()) {
      createMessage("メッセージを入力してください。", "error");
      return;
    }
    if (maxOutputTokens < 1) {
      setSettingsDialogOpen(true);
      createMessage("最大出力トークンは1以上にしてください。", "error");
      return;
    }
    if (historyCount < 0) {
      setSettingsDialogOpen(true);
      createMessage("送信する履歴数は0以上にしてください。", "error");
      return;
    }
    setIsRunning(true);

    const selectedHistoryList = historyCount >= 1 ? historyList.slice(-(historyCount * 2)) : [];
    try {
      const response = await fetch('/api/gemini', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input,
          selectedHistoryList,
          selectedModel,
          maxOutputTokens
        }),
      });

      if (!response.ok) {
        throw new Error('API request failed');
      }

      const result = await response.json();

      const userHistory: History = {
        role: "user",
        parts: [{ text: input }],
      };
      const modelHistory: History = {
        role: "model",
        parts: [{ text: result.text }],
      };
      setHistoryList(prev => [...prev, userHistory, modelHistory]);
      setInput("");
    } catch (error) {
      createMessage(String(error), "error");
    } finally {
      setIsRunning(false);
    }
  };

  return (
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
        <Container maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                send();
              }
            }}
            disabled={isRunning}
            required
            multiline
            rows={2}
            fullWidth
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: 1
            }}
          >
            <Tooltip arrow
              placement="left"
              title="設定"
            >
              <span>
                <IconButton
                  onClick={() => setSettingsDialogOpen(true)}
                  disabled={isRunning}
                  color="secondary"
                  sx={{ my: 1 }}
                >
                  <SettingsIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip arrow
              placement="left"
              title="送信"
            >
              <span>
                <Box sx={{ position: 'relative' }}>
                  <IconButton
                    onClick={send}
                    disabled={isRunning || !input.trim()}
                    color="primary"
                    sx={{ my: 1 }}

                  >
                    <SendIcon />
                  </IconButton>
                  {isRunning && (
                    <CircularProgress
                      size={40}
                      sx={{
                        color: "primary.main",
                        position: 'absolute',
                        top: 8,
                        left: -1,
                        zIndex: 1
                      }}
                    />
                  )}
                </Box>
              </span>
            </Tooltip>
          </Box>
        </Container>
      </Paper>
  );
}

export default Input;
